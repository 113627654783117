/// Helper class for reading structured binary data.
export class Struct {
	private byteOffset = 0;
	private buffer: ArrayBuffer;
	private dv: DataView;

	constructor(buffer: ArrayBuffer) {
		this.dv = new DataView(buffer);
		this.buffer = buffer;
	}

	Seek(off: number) {
		// sanity checking should be added later
		this.byteOffset = off;
	}

	Tell(): number {
		return this.byteOffset;
	}

	// Reader functions

	ReadArray(functor: () => any) {
		let len = this.ReadU32();
		let arr: any = [];
		for (let i = 0; i < len; ++i) arr.push(functor.call(this));
		return arr;
	}

	ReadString() {
		let len = this.ReadU32();
		let s = '';

		for (let i = 0; i < len; ++i) s += String.fromCharCode(this.ReadU16());

		return s;
	}

	ReadStringLen(maxStringLength: number) {
		let stringLength = this.ReadU32();
		let s = '';

		if(maxStringLength > stringLength)
			throw new Error(`string length ${stringLength} is past the max ${maxStringLength}`);

		for (let i = 0; i < stringLength; ++i)
			s += String.fromCharCode(this.ReadU16());

		return s;
	}

	ReadU8() {
		let i = this.dv.getUint8(this.byteOffset);
		this.byteOffset++;
		return i;
	}

	ReadS8() {
		let i = this.dv.getInt8(this.byteOffset);
		this.byteOffset++;
		return i;
	}

	ReadU16() {
		let i = this.dv.getUint16(this.byteOffset, false);
		this.byteOffset += 2;
		return i;
	}

	ReadS16() {
		let i = this.dv.getInt16(this.byteOffset, false);
		this.byteOffset += 2;
		return i;
	}

	ReadU32() {
		let i = this.dv.getUint32(this.byteOffset, false);
		this.byteOffset += 4;
		return i;
	}

	ReadS32() {
		let i = this.dv.getInt32(this.byteOffset, false);
		this.byteOffset += 4;
		return i;
	}

	ReadBuffer() {
		let count = this.ReadU32();
		let buf = this.buffer.slice(this.byteOffset, this.byteOffset + count);
		this.byteOffset += count;
		return buf;
	}

	// Writer functions
	// TODO: let these grow!. we can do that by just allocating a new buffer
	// then copying

	// Add an array with a fixed type
	WriteArray(arr: any[], functor: any) {
		this.WriteU32(arr.length);
		for (let elem of arr) functor.call(this, elem);
	}

	// Add a pascal UTF-16 string.
	WriteString(str: string) {
		this.WriteU32(str.length);
		for (var i = 0; i < str.length; ++i)
		 this.WriteU16(str.charCodeAt(i));
	}

	// writes a string with a max length.
	// will trim strings that are too long
	WriteStringLen(str: string, len: number) {
		let length = len;

		// pick the string length, but ONLY
		// if it is smaller or equal to our
		// max bound lenfth
		if(len <= str.length)
			length = str.length;
		
		this.WriteU32(length);

		for (let i = 0; i < length; ++i)
			this.WriteU16(str.charCodeAt(i));
	}

	WriteU8(i: number) {
		this.dv.setUint8(this.byteOffset, i);
		this.byteOffset++;
	}

	WriteS8(i: number) {
		this.dv.setInt8(this.byteOffset, i);
		this.byteOffset++;
	}

	WriteU16(i: number) {
		this.dv.setUint16(this.byteOffset, i, false);
		this.byteOffset += 2;
	}

	WriteS16(i: number) {
		this.dv.setInt16(this.byteOffset, i, false);
		this.byteOffset += 2;
	}

	WriteU32(i: number) {
		this.dv.setUint32(this.byteOffset, i, false);
		this.byteOffset += 4;
	}

	WriteS32(i: number) {
		this.dv.setInt32(this.byteOffset, i, false);
		this.byteOffset += 4;
	}

	WriteBuffer(buffer: ArrayBuffer) {
		let u8ar = new Uint8Array(buffer);
		this.WriteU32(buffer.byteLength);
		for (let i = 0; i < buffer.byteLength; ++i) this.WriteU8(u8ar[i]);
	}
}
