import { Struct } from './Struct.js';

export enum MessageType {
	// control
	Key,
	Mouse,
	Turn,

	// Display/audio
	DisplayRect,
	DisplaySize, // display changed size

	// user
	AddUser,
	RemUser,
}

export enum MouseButtons {
	Left = 1 << 0,
	Right = 1 << 1,
	Middle = 1 << 2,
	WheelUp = 1 << 3,
	WheelDn = 1 << 4
}


export const kMaxUserNameLength = 24;

/// This is a 16-bit value, sort of a structure if you will. 
/// 0x55 is the actual magic value.
/// 0x[vv] is the protocol version.
///
/// Any bumps to fields which are incompatible WILL require bumping the version field
/// to avoid older clients accidentally getting data they can't handle.
const kProtocolMagic = 0x5501;

export type ProtocolHeader = {
	magic: number;
	type: number;
	payloadSize: number;
};

export type ChatMessageObject = {
	username: string;
	message: string;
};

// Messages

export type DeserializedMessageRoot = { type: MessageType };

export type KeyMessage = DeserializedMessageRoot & {
	keysym: number;
	pressed: boolean;
};

export type MouseMessage = DeserializedMessageRoot & {
	x: number;
	y: number;
	buttons: MouseButtons; // Actually a bitmask
};

export type TurnMessage = DeserializedMessageRoot & {};

export type TurnServerMessage = DeserializedMessageRoot & {
	time: number;
	turnQueue: string[];
};

// This is using browser types for simplicity's sake
export type DisplayRectMessage = DeserializedMessageRoot & {
	x: number;
	y: number;
	data: ArrayBuffer;
};

export type DisplaySizeMessage = DeserializedMessageRoot & {
	width: number;
	height: number;
};


export type AddUserMessage = DeserializedMessageRoot & {
	user: string;
};

export type RemUserMessage = DeserializedMessageRoot & {
	user: string;
};


export type AnyMessage =
	| KeyMessage
	| MouseMessage
	| TurnMessage
	| TurnServerMessage
	| DisplayRectMessage
	| DisplaySizeMessage
	| RemUserMessage;

export type DeserializedMessage = AnyMessage;

export const kProtocolHeaderSize = 8;

export class MessageEncoder {
	private struct: Struct|null = null;
	private buffer: ArrayBuffer|null = null;

	Init(byteSize: number) {
		this.buffer = new ArrayBuffer(byteSize + kProtocolHeaderSize);
		this.struct = new Struct(this.buffer);
		this.InitHeader();
	}

	SetKeyMessage(keysym: number, pressed: boolean) {
		this.SetTypeCode(MessageType.Key);
		this.struct?.WriteU16(keysym);
		this.struct?.WriteU8(pressed == true ? 1 : 0);
	}

	SetMouseMessage(x: number, y: number, buttons: MouseButtons) {
		this.SetTypeCode(MessageType.Mouse);
		this.struct?.WriteU16(x);
		this.struct?.WriteU16(y);
		this.struct?.WriteU8(buttons);
	}

	SetTurnMessage() {
		this.SetTypeCode(MessageType.Turn);
	}

	SetTurnSrvMessage(ms: number, usersQueue: Array<string>) {
		this.SetTypeCode(MessageType.Turn);
		this.struct?.WriteU32(ms);
		this.struct?.WriteArray(usersQueue, this.struct?.WriteString);
	}

	SetDisplayRectMessage(x: number, y: number, buffer: ArrayBuffer) {
		this.SetTypeCode(MessageType.DisplayRect);
		this.struct?.WriteU16(x);
		this.struct?.WriteU16(y);
		this.struct?.WriteBuffer(buffer);
	}

	SetDisplaySizeMessage(w: number, h: number) {
		this.SetTypeCode(MessageType.DisplaySize);
		this.struct?.WriteU16(w);
		this.struct?.WriteU16(h);
	}


	SetAddUserMessage(user: string) {
		this.SetTypeCode(MessageType.AddUser);
		this.struct?.WriteString(user);
	}

	SetRemUserMessage(user: string) {
		this.SetTypeCode(MessageType.RemUser);
		this.struct?.WriteString(user);
	}


	// Setup some stuff and then return the final message
	Finish() {
		if(this.struct == null || this.buffer == null)
			throw new Error('no');

		let endOffset :number = this.struct?.Tell();
		this.struct?.Seek(4); // seek to size offset
		this.struct?.WriteU32(endOffset - kProtocolHeaderSize);
		this.struct?.Seek(endOffset);
		return this.buffer?.slice(0, endOffset);
	}

	private InitHeader() {
		this.struct?.Seek(0);
		this.struct?.WriteU16(kProtocolMagic);
		this.struct?.WriteU16(0); // No message type yet
		this.struct?.WriteU32(0); // No payload size yet
	}

	private SetTypeCode(type: MessageType) {
		if(this.struct == null)
			throw new Error('no');

		let oldOff = this.struct?.Tell();
		this.struct?.Seek(2); // seek to type offset
		this.struct?.WriteU16(type);
		this.struct?.Seek(oldOff);
	}
}


export class MessageDecoder {

	static async ReadMessage(buffer: ArrayBuffer, asClient: boolean): Promise<DeserializedMessage> {
		return new Promise((res, rej) => {
			MessageDecoder.ReadMessageSync(buffer, asClient, (err: Error|null, message: DeserializedMessage|null) => {
				if (err) rej(err);

				if(message != null) {
					res(message);
				}
			});
		});
	}

	private static ReadMessageSync(buffer: ArrayBuffer, asClient: boolean, callback: (err: Error|null, message: DeserializedMessage|null) => void) {
		let struct = new Struct(buffer);

		// Read and verify the header
		let header: ProtocolHeader = {
			magic: struct.ReadU16(),
			type: struct.ReadU16() as MessageType,
			payloadSize: struct.ReadU32()
		};

		if (header.magic !== kProtocolMagic) return callback(new Error('Invalid protocol message'), null);

		if(header.payloadSize > buffer.byteLength)
			return callback(new Error('invalid header'), null);

		let message: DeserializedMessage = {
			type: header.type
		};

		switch (header.type) {
			case MessageType.Key:
				if (asClient) {
					return callback(new Error('unexpected server->client message'), null);
				}
				(message as KeyMessage).keysym = struct.ReadU16();
				(message as KeyMessage).pressed = struct.ReadU8() == 1 ? true : false;
				return callback(null, message);
				break;

			case MessageType.Mouse:
				if (asClient) {
					return callback(new Error('unexpected server->client message'), null);
				}
				(message as MouseMessage).x = struct.ReadU16();
				(message as MouseMessage).y = struct.ReadU16();
				(message as MouseMessage).buttons = struct.ReadU8() as MouseButtons;
				return callback(null, message);

			case MessageType.Turn:
				if (asClient) {
					// the server->client version of this message contains fields
					(message as TurnServerMessage).time = struct.ReadU32();
					(message as TurnServerMessage).turnQueue = struct.ReadArray(struct.ReadString);
				}
				return callback(null, message);
				break;

			case MessageType.DisplayRect:
				if (asClient) {
					(message as DisplayRectMessage).x = struct.ReadU16();
					(message as DisplayRectMessage).y = struct.ReadU16();
					(message as DisplayRectMessage).data = struct.ReadBuffer();
					return callback(null, message);
				} else {
					return callback(new Error('unexpected client->server message'), null);
				}
				break;

			case MessageType.DisplaySize:
				if (asClient) {
					(message as DisplaySizeMessage).width = struct.ReadU16();
					(message as DisplaySizeMessage).height = struct.ReadU16();
				} else {
					return callback(new Error('unexpected client->server message'), null);
				}
				return callback(null, message);
				break;

			case MessageType.AddUser:
				if (asClient) {
					(message as AddUserMessage).user = struct.ReadString();
					return callback(null, message);
				} else {
					return callback(new Error('unexpected client->server message'), null);
				}

			case MessageType.RemUser:
				if (asClient) {
					(message as RemUserMessage).user = struct.ReadString();
					return callback(null, message);
				} else {
					return callback(new Error('unexpected client->server message'), null);
				}
				break;

			default:
				return callback(new Error(`unknown type code ${header.type}`), null);
		}
	}


}
