
import * as Shared from '@socketcomputer/shared';
import {GetKeysym} from "./keyboard";
import {Mouse} from "./mouse";

type UserRecord = {
	username: string
};


async function sleep(ms: number) : Promise<void> {
	return new Promise((res) => {
		setTimeout(() => res(), ms);
	});
}


let turnInt: number = -1;

function waitingTimer(text: string, ms: number, dot: boolean = true) {
	let dots = '';
	let timer = document.querySelector('.turn-timer') as HTMLDivElement;

	if(turnInt != -1) {
		clearInterval(turnInt);
	}

	// @ts-ignore (for some reason it's assuming these are Node)
	turnInt = setInterval(() => {
	  ms -= 1000;
	  let seconds = Math.floor(ms / 1000);
	  if (seconds <= 0) {
		clearInterval(turnInt);
		turnInt = -1;

		timer.innerText = "";
	  } else {
		if (dots.length < 3)
		  dots += '.';
		else
		  dots = '';
  
		var str = text + ' in ~' + seconds + ' seconds';
		if (dot) str += dots;
		timer.innerText = str;
	  }
	}, 1000);
  }

// client for
class SocketClient {
	private websocket: WebSocket|null = null;
	private url = "";

	private selfNamed = false;
	private name = "";
	private userList = new Array<UserRecord>();

	private hasTurn = false;
	private mouse = new Mouse();

	private canvas:HTMLCanvasElement|null = null;
	private canvasCtx : CanvasRenderingContext2D|null = null;


	constructor(url: string, canvas: HTMLCanvasElement) {
		this.url = url
		this.canvas = canvas;
		this.canvasCtx = canvas.getContext('2d');

		let self = this;

		this.canvas.addEventListener('click', async () => {
			if(!self.hasTurn) {
				self.turnRequest();
			}
		});

		this.canvas.addEventListener(
			'mousedown',
			(e: MouseEvent) => {
				if(self.hasTurn) {
					self.mouse.initFromMouseEvent(e);
					self.sendMouse(self.mouse.x, self.mouse.y, self.mouse.Mask());
				}
			},
			{
				capture: true
			}
		);

		this.canvas.addEventListener(
			'mouseup',
			(e: MouseEvent) => {
				if(self.hasTurn) {
					self.mouse.initFromMouseEvent(e);
					self.sendMouse(self.mouse.x, self.mouse.y, self.mouse.Mask());
				}
			},
			{
				capture: true
			}
		);

		this.canvas.addEventListener(
			'mousemove',
			(e: MouseEvent) => {
				if(self.hasTurn) {
					self.mouse.initFromMouseEvent(e);
					self.sendMouse(self.mouse.x, self.mouse.y, self.mouse.Mask());
				}
			},
			{
				capture: true
			}
		);

		this.canvas.addEventListener(
			'keydown',
			(e: KeyboardEvent) => {
				e.preventDefault();
				if(self.hasTurn) {
					let keysym = GetKeysym(e.keyCode, e.key, e.location);
					if (keysym === null) return;
					self.sendKey(keysym, true);
				}
			},
			{
				capture: true
			}
		);

		this.canvas.addEventListener(
			'keyup',
			(e: KeyboardEvent) => {
				e.preventDefault();
				if(self.hasTurn) {
					let keysym = GetKeysym(e.keyCode, e.key, e.location);
					if (keysym === null) return;
					self.sendKey(keysym, false);
				}
			},
			{
				capture: true
			}
		);

		this.canvas.addEventListener(
			'wheel',
			(ev: WheelEvent) => {
				ev.preventDefault();
				if(self.hasTurn) {

					self.mouse.initFromWheelEvent(ev);

					self.sendMouse(self.mouse.x, self.mouse.y, self.mouse.Mask());

					// this is a very, *very* ugly hack but it seems to work so /shrug
					if (self.mouse.scrollUp) 
						self.mouse.scrollUp = false;
					else if (self.mouse.scrollDown) 
						self.mouse.scrollDown = false;

					self.sendMouse(self.mouse.x, self.mouse.y, self.mouse.Mask());
				}
			},
			{
				capture: true
			}
		);

		this.canvas.addEventListener('contextmenu', (e) => e.preventDefault());
	}

	public connect() {
		this.websocket = new WebSocket(this.url);
		this.websocket.binaryType = 'arraybuffer'; // its 2024 people.
		this.websocket.addEventListener('open', this.onWsOpen.bind(this));
		this.websocket.addEventListener('message', this.onWsMessage.bind(this));
		this.websocket.addEventListener('close', this.onWsClose.bind(this));
	}

	private onWsOpen() {
	}

	private async onWsMessage(e: MessageEvent) {
		// no guacmoale or shity fucking sockret io here.
		if(typeof(e.data) == "string")
			return;

		try {
			let message = await Shared.MessageDecoder.ReadMessage(e.data as ArrayBuffer, true);

			switch(message.type) {
				case Shared.MessageType.DisplaySize:
					this.resizeDisplay((message as Shared.DisplaySizeMessage));
					break;
				case Shared.MessageType.DisplayRect:
					this.drawRects((message as Shared.DisplayRectMessage));
					break;

				case Shared.MessageType.AddUser:
					this.addUser((message as Shared.AddUserMessage));
					break;

				case Shared.MessageType.RemUser:
					this.remUser((message as Shared.RemUserMessage));
					break;

				case Shared.MessageType.Turn:
					this.turnQueueUpdate((message as Shared.TurnServerMessage));
					break;

				default:
					console.log(`Unhandled message type ${message.type}`);
					break;
			}
		} catch(e) {
			console.log("Is not works..", e)
		}
	}

	private async onWsClose() {
		this.websocket?.removeEventListener("open", this.onWsOpen);
		this.websocket?.removeEventListener("message", this.onWsMessage);
		this.websocket?.removeEventListener("close", this.onWsClose);
		this.websocket = null;

		// reset state
		this.resetState();

		// reconnect
		await sleep(1000);
		this.connect();
	}

	private resetState() {
		this.selfNamed = false;
		this.name = "";
		this.userList = [];
		this.updateUserCount();
	}

	async SendMessage(messageGenerator: (encoder: Shared.MessageEncoder) => ArrayBuffer) {
		await this.SendBuffer(messageGenerator(new Shared.MessageEncoder()));
	}

	async SendBuffer(buffer: ArrayBuffer): Promise<void> {
		return new Promise((res, rej) => {
			this.websocket?.send(buffer);
			res();
		});
	}

	private async turnRequest() {
		await this.SendMessage((enc: Shared.MessageEncoder) => {
			enc.Init(4);
			enc.SetTurnMessage();
			return enc.Finish();
		});
	}

	private async sendMouse(x:number, y:number, buttonMask: number) {
		await this.SendMessage((enc: Shared.MessageEncoder) => {
			enc.Init(8);
			enc.SetMouseMessage(x, y, buttonMask as Shared.MouseButtons);
			return enc.Finish();
		});
	}

	private async sendKey(keysym: number, pressed: boolean) {
		await this.SendMessage((enc: Shared.MessageEncoder) => {
			enc.Init(8);
			enc.SetKeyMessage(keysym, pressed);
			return enc.Finish();
		});
	}

	private resizeDisplay(message: Shared.DisplaySizeMessage) {
		if(this.canvas == null)
			return;
		this.canvas!.width = message.width;
		this.canvas!.height = message.height;
	}

	private updateUserCount() {
		let elem : HTMLSpanElement = document.getElementById("count") as HTMLSpanElement;
		elem.innerText = this.userList.length.toString();
	}

	private addUser(message: Shared.AddUserMessage) {
		if(!this.selfNamed) {
			this.name = message.user;
			this.selfNamed = true;
		}

		this.userList.push({
			username: message.user
		});

		this.updateUserCount();
	}

	private remUser(message: Shared.RemUserMessage) {
		let index = this.userList.findIndex((u) => {
			return u.username == message.user;
		});

		if(index !== -1)
			this.userList.splice(index, 1);

		this.updateUserCount();
	}

	private turnQueueUpdate(message: Shared.TurnServerMessage) {
		if(message.turnQueue.length != 0) {
			if(message.turnQueue[0] == this.name) {
				waitingTimer("Turn ends", message.time);
				this.hasTurn = true;
			} else {
				waitingTimer("Waiting for turn", message.time, true);
				this.hasTurn = false;
			}
		} else {
			// generally speaking an empty turn queue means NO one has a turn
			this.hasTurn = false;
		}
	}

	private drawRects(message: Shared.DisplayRectMessage) {
		let blob = new Blob([message.data]);
		createImageBitmap(blob)
		.then((image) => {
			this.canvasCtx?.drawImage(image, message.x, message.y);
		}).catch((err) => {
			console.error(`Error decoding rect for some reason...`, err);
		});
	}
}

let globalclient = null;

document.addEventListener("DOMContentLoaded", async () => {
	globalclient = new SocketClient("wss://socket.computer/ws", document.getElementById("xp-canvas") as HTMLCanvasElement);
	globalclient.connect();
})
